import { HrRefreshCreatePayload, HrRefreshHistory, Notifications, UnreadNotificationsCount } from 'pages/Dashboard/types/healthRecords.types';
import { QueryFunctionContext } from 'react-query';
import { healthRecordRefreshUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const createHrRefresh = (
  method: HttpType['post'],
  providerId: string,
  patientId: string,
  payload: HrRefreshCreatePayload,
) => method(healthRecordRefreshUrls.createHrRefresh.apiUrls(providerId, patientId).create, payload);

export const healthRecordRefreshHistory = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<HrRefreshHistory> {
  const [, providerId, patientId] = queryKey;
  return method(
    healthRecordRefreshUrls.hrRefreshHistory.apiUrls(providerId as
    string, patientId as string).list,
  );
};

export const getNotifications = (method: HttpType['get'], providerId: string, queryParams?: string) => function async({ queryKey }: QueryFunctionContext): Promise<Notifications> {
  const [, innerProviderId] = queryKey;
  return method(
    healthRecordRefreshUrls.notifications.apiUrls(innerProviderId as
      string, queryParams).list,
  );
};

export const unreadNotificationsCount = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<UnreadNotificationsCount> {
  const [, providerId] = queryKey;
  return method(
    healthRecordRefreshUrls.unreadNotifications.apiUrls(providerId as
        string).count,
  );
};

export const updateNotificationStatus = (method: HttpType['put'], providerId: string, notificationId: string, payload: object) => method(healthRecordRefreshUrls.markNotificationStatus.apiUrls(
    providerId as string,
    notificationId as string,
).update, payload);

export const updateAllNotificationsAsRead = (method: HttpType['put'], providerId: string, payload: object) => method(healthRecordRefreshUrls.markAllNotificationsRead.apiUrls(
  providerId as string,
).update, payload);

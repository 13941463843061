import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { useHttp } from 'hooks/use-fetch';
import BorderTableRow from 'components/Common/BorderTableRow';
import { useQuery } from 'react-query';
import ContentLoader from 'components/Common/ContentLoader';
import { fetchAuditLogs } from 'pages/AdminPortal/services/auditlogs.services';
import { format } from 'date-fns';
import { AuditTrail } from 'pages/AdminPortal/types';
import { CategoryType } from 'pages/Dashboard/types/whoiam.types';

interface KeyValueTableProps {
  data: Record<string, { value?: string; order: number }>;
}

function KeyValueTable({ data }: KeyValueTableProps) {
  const sortedKeys = Object.keys(data).sort((a, b) => data[a].order - data[b].order);

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: '8px' }}>
      {sortedKeys.map((key) => (
        <React.Fragment key={key}>
          <div style={{ fontWeight: 'bold' }}>{key}</div>
          <div>{data[key].value}</div>
        </React.Fragment>
      ))}
    </div>
  );
}

enum ActionTypes {
  ResendActivationEmail = 'Resend-Activation-Email',
  CreateAssociation = 'Create-Association',
  Bundling = 'Bundling',
  ApproveProviderRequest = 'ApproveProviderRequest',
  UpdateProviderEmail = 'Update-Provider-Email',
  DeletePatient = 'Delete-Patient',
}

export default function AuditLogs() {
  const { http } = useHttp();

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const {
    data: auditLogList,
    isFetching: auditLogListFetching,
    refetch: refetchAuditLogs,
  } = useQuery(['audit-logs-list', offset, limit], {
    queryFn: fetchAuditLogs(http.get, `?offset=${offset}&limit=${limit}`),
  });

  const auditLogListResponse = auditLogList?.auditTrails ?? [];
  const totalCount = +(auditLogList?.pagination.total ?? 0);

  React.useEffect(() => {
    refetchAuditLogs();
  }, [refetchAuditLogs]);

  const tableCells = React.useMemo(() => ['Action', 'Taken by', 'Description', 'Time'], []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const noData = auditLogListResponse.length === 0;

  const getLogDescription = (log: AuditTrail) => {
    const provider = log.metadata.provider_details?.[0] ?? { first_name: '', last_name: '', email: '' };
    const providers = log.metadata.provider_details?.map((p) => `${p.first_name} ${p.last_name}`).join(', ');
    const commonData = {
      'Provider name': { value: `${provider.first_name} ${provider.last_name}`, order: 1 },
      'Provider email': { value: provider.email, order: 2 },
    };

    const data: Record<string, { value?: string, order: number }> = {
      [ActionTypes.ResendActivationEmail]: commonData,
      [ActionTypes.CreateAssociation]: {
        'Practice name': { value: log.metadata.practice_name ?? '', order: 1 },
        Staff: { value: `${log.metadata.staff_first_name} ${log.metadata.staff_last_name}`, order: 2 },
        'Associated providers': { value: providers ?? '', order: 3 },
      },
      [ActionTypes.Bundling]: {
        'Practice name': { value: log.metadata.practice_name ?? '', order: 1 },
        ...(log.metadata.bundle_items && {
          [log.metadata.bundle_type === CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE ? 'Rating scales' : 'Personality profiles']: {
            value: log.metadata.bundle_items.join(', '), order: 3,
          },
        }),
      },
      [ActionTypes.ApproveProviderRequest]: commonData,
      [ActionTypes.UpdateProviderEmail]: {
        'Provider name': { value: providers ?? '', order: 1 },
        'Old email': { value: log.metadata.old_email ?? '', order: 2 },
        'New email': { value: log.metadata.new_email ?? '', order: 3 },
      },
      [ActionTypes.DeletePatient]: {
        'Provider name': commonData['Provider name'],
        'Patient Id': { value: log.metadata.patient_id, order: 2 },
      },
    }[log.action] || {};

    return <KeyValueTable data={data} />;
  };

  return (
    <ContentLoader
      isFetching={auditLogListFetching}
      isError={false}
      minHeight={400}
      noDataText={noData ? 'No logs found' : ''}
    >
      <>
        <TableContainer>
          <Table aria-label='audit-logs-table'>
            <TableHead>
              <TableRow>
                {tableCells.map((cell) => (
                  <TableCell key={cell}>{cell}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {auditLogListResponse.map((log) => (
                <BorderTableRow key={`audit-log-${log.id}`}>
                  <TableCell>{log.description}</TableCell>
                  <TableCell>{`${log.providerFirstName} ${log.providerLastName} (${log.providerEmail})`}</TableCell>
                  <TableCell sx={{ width: '40%' }}>{getLogDescription(log)}</TableCell>
                  <TableCell>
                    {format(new Date(log.timeAudit?.createdAt as string), 'MM/dd/yyyy, hh:mm aaa')}
                  </TableCell>
                </BorderTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component='div'
          count={totalCount ?? 0}
          rowsPerPage={limit}
          page={Math.floor(offset / limit)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    </ContentLoader>
  );
}

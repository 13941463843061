import React, { useRef, useCallback } from 'react';
import Spinner from 'components/Common/Spinner';

interface InfiniteScrollProps<T> {
  items: T[];
  hasMore: boolean;
  isLoading: boolean;
  loadMore: () => void;
  renderItem: (item: T, index: number) => React.ReactNode;
  getItemKey: (item: T) => string | number;
}

function InfiniteScroll<T>({
  items,
  hasMore,
  isLoading,
  loadMore,
  renderItem,
  getItemKey,
}: InfiniteScrollProps<T>): React.ReactElement {
  const observer = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            loadMore();
          }
        },
        {
          root: null,
          rootMargin: '100px',
          threshold: 0.1,
        },
      );
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, loadMore],
  );

  return (
    <div>
      {items.map((item, index) => {
        const key = getItemKey(item);
        const content = renderItem(item, index);
        return index === items.length - 1 ? (
          <div ref={lastElementRef} key={key}>
            {content}
          </div>
        ) : (
          <div key={key}>{content}</div>
        );
      })}
      {isLoading && <Spinner />}
    </div>
  );
}

export default InfiniteScroll;

import { FamilyHistoryAPIResponse } from 'pages/Dashboard/types/familyHistory.types';
import { QueryFunctionContext } from 'react-query';
import { familyHistoryUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const fetchFamilyHistoryList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<FamilyHistoryAPIResponse> {
  const [, providerId, patientId, refreshId] = queryKey;
  return (
    method(familyHistoryUrls.listFamilyHistory.apiUrls(
      providerId as string,
      patientId as string,
      refreshId as string,
    ).list)
  );
};

export default fetchFamilyHistoryList;


import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { Providers } from 'pages/Dashboard/types/providers.types';
import { GoogleDate } from 'utils/dateUtils';

export enum HrRefreshHistoryStatus {
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INTIAL_REQUEST = 'INITIAL_REQUEST',
}

export enum NotificationAction {
  EMPTY_NOTIFICATION = 'EMPTY_NOTIFICATION',
  HEALTH_RECORD_REFRESH_NOTIFICATION = 'HEALTH_RECORD_REFRESH_NOTIFICATION',
  HEALTH_RECORD_NOTIFICATION = 'HEALTH_RECORD_NOTIFICATION',
}

export interface HrRefreshCreatePayload {
  appointment: { date: GoogleDate };
}

export interface RefreshHistory {
  scheduledBy: Providers;
  scheduledOn: GoogleDate;
  refreshedOn: GoogleDate;
  status: HrRefreshHistoryStatus;
  hrCount: number;
  refreshId: string;
}

export interface HrRefreshHistory {
  refreshHistory: RefreshHistory[];
}

export interface Notification {
    id: string;
    title: string;
    message: string;
    unread: boolean;
    action: string;
    createdAt: GoogleDate;
    metadata: {
      patient: PatientObj;
      hrCount: number;
      appointmentDate: GoogleDate;
  refreshId: string;
  };
}

export interface Pagination {
  offset: number;
  limit: number;
  total: string;
}

export interface Notifications {
  notifications: Notification[];
  pagination: Pagination;
}

export interface UnreadNotificationsCount {
  notifications: {
    total: number;
    unread: number;
 }
}

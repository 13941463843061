import React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import { Close, Refresh } from '@mui/icons-material';
import Theme from 'theme';
import { UseQueryResult } from 'react-query';
import { HrRefreshHistory, HrRefreshHistoryStatus, RefreshHistory } from 'pages/Dashboard/types/healthRecords.types';
import { calculateDaysDifference, formatEventDate, formatGoogleDate, GoogleDate } from 'utils/dateUtils';
import { DaysDifferenceHrRefresh } from 'utils/constants';

interface HistoryDrawerProps {
    isOpen: boolean;
    toggleDrawer: (open: boolean) => void;
    getHrRefreshHistory: UseQueryResult<HrRefreshHistory, unknown>;
}

function MedicalHistoryDrawer({ isOpen, toggleDrawer, getHrRefreshHistory }: HistoryDrawerProps) {
  const handleDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    toggleDrawer(false);
  };

  const currentDate = new Date();
  const [lastBoxHeight, setLastBoxHeight] = React.useState<number | null>(null);
  const lastBoxRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (lastBoxRef.current) {
      setLastBoxHeight(lastBoxRef.current.offsetHeight);
    }
  }, [getHrRefreshHistory.data]);

  const getEventDateText = (eventDate: GoogleDate, current: Date) => {
    const daysDiff = calculateDaysDifference(eventDate, current);

    if (daysDiff === 0) {
      return 'Today';
    }
    else if (daysDiff <= 59) {
      const text = daysDiff === 1 ? `${daysDiff} day ago` : `${daysDiff} days ago`;
      return (
        <Tooltip title={`${formatGoogleDate(eventDate, true)}`} arrow>
          <span>{text}</span>
        </Tooltip>
      );
    }
    else {
      return formatEventDate(eventDate, current);
    }
  };

  const getRemainingDaysText = (daysDifference: number) => {
    const remainingDays = Math.abs(60 - daysDifference);
    if (remainingDays > 0) {
      return remainingDays === 1 ? `${remainingDays} day` : `${remainingDays} days`;
    }
    return null;
  };

  const getStatusText = (event: RefreshHistory) => {
    if (event.status === HrRefreshHistoryStatus.IN_PROGRESS) {
      return 'In progress';
    }
    if (event.hrCount === 1) {
      return '1 new record';
    }
    return `${event.hrCount} new records`;
  };

  const refreshHistory = getHrRefreshHistory.data?.refreshHistory[0];
  const scheduledOn = refreshHistory?.scheduledOn;
  const daysDifference = scheduledOn ? calculateDaysDifference(scheduledOn, currentDate) : 0;

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={handleDrawerClose}
      sx={{
        height: 'lg(100%)',
        width: 'md(35%)',
        '& .MuiDrawer-paper': { width: '35%', boxSizing: 'border-box' },
      }}
    >
      <Box display='flex' flexDirection='column' flex={1} overflow='hidden'>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          p={2}
          pb={3}
          mt={1}
          mb={1}
          alignItems='center'
          justifyContent='space-between'
          height={10}
          sx={{ borderBottom: `1px solid ${Theme.custom.colors.backgroundColorSecondary}` }}
        >
          <Typography fontSize={20} fontWeight={500} color={Theme.custom.colors.lightTextPrimary} variant='h6'>
            Medical records refresh history
          </Typography>
          <Close sx={{ color: '#0000008A' }} onClick={handleDrawerClose} />
        </Box>
        <Box
          flex={1}
          maxHeight='calc(100% - 100px)'
          overflow='scroll'
        >
          {daysDifference <= DaysDifferenceHrRefresh && (
          <Box pt={2} pl={1.5}>
            <Typography fontSize={16} color={Theme.custom.colors.lightTextPrimary} variant='body1' display='flex'>
              Available to refresh again in
              <Box component='span' ml={0.5}>
                <Typography fontWeight={700}>
                  {getHrRefreshHistory.data?.refreshHistory[0]?.scheduledOn
                && getRemainingDaysText(daysDifference)}
                </Typography>
              </Box>
            </Typography>
          </Box>
          )}
          <Box pl={2} pt={1}>
            <Box position='relative' pl={3}>
              {getHrRefreshHistory.data?.refreshHistory.map((event, index) => {
                const statusText = getStatusText(event);
                const isLastEvent = index === (getHrRefreshHistory?.data
                  ?.refreshHistory?.length ?? 0) - 1;
                return (
                  <Box
                    key={`${event.scheduledOn.day}-${event.scheduledOn.month}-${event.scheduledOn.year}`}
                    position='relative'
                    pt={isLastEvent ? 1 : 2}
                    pb={3}
                    ref={isLastEvent ? lastBoxRef : null}
                  >
                    {event.status === HrRefreshHistoryStatus.IN_PROGRESS ? (
                      <Refresh
                        fontSize='small'
                        sx={{
                          color: Theme.custom.colors.lightTextSecondary,
                          position: 'absolute',
                          left: -27,
                          top: '20%',
                          transform: 'translateY(-50%)',
                          zIndex: 1,
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          backgroundColor: Theme.custom.colors.lightTextSecondary,
                          borderRadius: '50%',
                          position: 'absolute',
                          left: -24,
                          top: '22.5%',
                          transform: 'translateY(-50%)',
                          zIndex: 1,
                        }}
                      />
                    )}
                    {index !== (getHrRefreshHistory?.data?.refreshHistory?.length ?? 0) - 1 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        left: -19,
                        top: 'calc(25% + 6px)',
                        height: 'calc(100% - 24px)',
                        width: 2,
                        backgroundColor: Theme.custom.colors.lightTextSecondary,
                      }}
                    />
                    )}
                    {getHrRefreshHistory?.data?.refreshHistory?.length > 1
                    && index === (getHrRefreshHistory?.data?.refreshHistory?.length ?? 0) - 1 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        left: -19,
                        top: 'calc(25% + 7px)',
                        height: lastBoxHeight ? `${lastBoxHeight - 54}px` : '36%',
                        width: 2,
                        backgroundColor: Theme.custom.colors.lightTextSecondary,
                      }}
                    />
                    )}
                    <Typography
                      color={Theme.custom.colors.lightTextSecondary}
                      variant='body2'
                      fontWeight={400}
                      mb={0.5}
                    >
                      {getEventDateText(event.scheduledOn, currentDate)}
                    </Typography>
                    {event.status === HrRefreshHistoryStatus.INTIAL_REQUEST
                     && event.hrCount === 0 ? (
                        null
                      ) : (
                        <Typography variant='body1' fontWeight={400} mb={0.5}>
                          {statusText}
                        </Typography>
                      )}
                    <Typography color={Theme.custom.colors.lightTextSecondary} variant='body2' className='fs-mask'>
                      {event.status === HrRefreshHistoryStatus.INTIAL_REQUEST
                        ? 'Initial request' : `Requested by 
                    ${event.scheduledBy?.firstName} ${event.scheduledBy?.lastName}`}
                    </Typography>
                    {index === 0 && event.status === HrRefreshHistoryStatus.IN_PROGRESS && (
                    <Typography variant='body2' color={Theme.custom.colors.lightTextSecondary}>
                      This may take up to 36 hours.
                      A notification will be sent when the refresh completes.
                    </Typography>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default MedicalHistoryDrawer;

import React from 'react';
import {
  Drawer,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Box,
  Divider,
  Tabs,
  Tab,
  Tooltip,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Theme from 'theme';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMutation, useQuery } from 'react-query';
import { healthRecordRefreshUrls } from 'utils/apiUrls';
import { useHttp } from 'hooks/use-fetch';
import { getNotifications, unreadNotificationsCount, updateAllNotificationsAsRead, updateNotificationStatus } from 'pages/Dashboard/services/healthrecords.services';
import { formatGoogleDate, formatTimeDifference } from 'utils/dateUtils';
import { Notification, NotificationAction } from 'pages/Dashboard/types/healthRecords.types';
import { useLocation, useNavigate } from 'react-router-dom';
import * as spaUrls from 'utils/spaUrls';
import { logSentryError, trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import InfiniteScroll from 'components/Common/InfiniteScroll';
import Toast, { ToastProps, ToastType } from 'components/Common/Toast';

interface NotificationsDrawerProps {
    isOpen: boolean;
    toggleDrawer: (open: boolean) => void;
}

function NotificationsDrawer({ isOpen, toggleDrawer }: NotificationsDrawerProps) {
  const location = useLocation();
  const { selectedProviderName, selectedProviderId } = useHttp();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [offset, setOffset] = React.useState<number>(0);
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  const [toastProps, setToastProps] = React.useState<ToastProps | undefined>();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [prevPathname, setPrevPathname] = React.useState(location.pathname);

  const { http, providerId, providerName } = useHttp();
  const navigate = useNavigate();

  const notificationsProviderId = selectedProviderId !== 'all' && selectedProviderId ? selectedProviderId : providerId;
  const notificationsCount = useQuery({
    queryKey: [healthRecordRefreshUrls.unreadNotifications.queryUrl, notificationsProviderId],
    queryFn: unreadNotificationsCount(http.get),
    enabled: !!providerId,
  });

  const { isFetching } = useQuery(
    [healthRecordRefreshUrls.notifications.queryUrl, notificationsProviderId, tabIndex, offset],
    () => {
      const queryParams = tabIndex === 1 ? `unread=true&offset=${offset}&limit=20` : `all=true&offset=${offset}&limit=20`;
      return getNotifications(http.get, notificationsProviderId, queryParams)({
        queryKey: [healthRecordRefreshUrls.notifications.queryUrl,
          notificationsProviderId, queryParams],
        meta: undefined,
      });
    },
    {
      onSuccess: (data) => {
        setTotal(+data.pagination.total);
        if (data.notifications.length !== 0) {
          setNotifications((prev) => [...prev, ...data.notifications]);
        }
      },
    },
  );

  React.useEffect(() => {
    if (location.pathname !== prevPathname) {
      setPrevPathname(location.pathname);
      toggleDrawer(false);
    }
  }, [location.pathname, prevPathname, toggleDrawer]);

  const handleDrawerClose = (event: React.KeyboardEvent | React.MouseEvent, reason?: string) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    toggleDrawer(false);
  };

  const { mutate: markNotificationAsRead } = useMutation({
    mutationFn: (notificationId: string) => updateNotificationStatus(
      http.put,
      providerId,
      notificationId,
      {},
    ),
    onSuccess: (data, notificationId) => {
      notificationsCount.refetch();
      setNotifications((prevNotifications) => prevNotifications
        .map((notif) => (notif.id === notificationId ? { ...notif, unread: false } : notif)));
    },
  });

  const handleNotificationClick = (
    notificationId: string,
    refreshId: string,
    patientId: string,
    hrCount: number,
    action: string,
  ) => () => {
    markNotificationAsRead(notificationId);
    trackMixpanelEvent(MixpanelEventName.NOTIFICATION_VIEW_CLICKED, {
      notificationId,
      patientId,
      'provider id': providerId ?? '',
      'logged in provider name': providerName ?? '',
      'selected provider name': selectedProviderName ?? '',
    });

    const stateData = {
      notificationId,
      hrCount,
      ...(action === NotificationAction.HEALTH_RECORD_REFRESH_NOTIFICATION && { refreshId }),
    };
    if (window.location.pathname === `/patient/${patientId}`) {
      window.location.reload();
      navigate(spaUrls.mainNav.patientDetail(patientId), {
        state: stateData,
        replace: true,
        relative: 'path',
      });
    }
    else {
      navigate(spaUrls.mainNav.patientDetail(patientId), {
        state: stateData,
        replace: true,
        relative: 'path',
      });
    }
    toggleDrawer(false);
  };

  const fetchMoreNotifications = () => {
    setOffset((prevOffset) => prevOffset + 20);
  };

  const markAllNotificationsButton = () => {
    trackMixpanelEvent(MixpanelEventName.NOTIFICATION_MARK_ALL_AS_READ_CLICKED, {
      'provider id': providerId ?? '',
      'logged in provider name': providerName ?? '',
      'selected provider name': selectedProviderName ?? '',
    });
    setIsConfirmDialogOpen(true);
  };

  const { mutate: markAllNotificationsAsRead } = useMutation({
    mutationFn: () => updateAllNotificationsAsRead(
      http.put,
      providerId,
      {},
    ),
    onSuccess: () => {
      notificationsCount.refetch();
      setNotifications((prevNotifications) => prevNotifications
        .map((notif) => ({ ...notif, unread: false })));
      setToastProps({ message: 'All notifications marked as read', open: true, type: ToastType.success });
      setIsConfirmDialogOpen(false);
    },
    onError: (e) => {
      const errorMessage = ((e instanceof Error ? e.message : e)
          || 'You are not authorised to perform this action') as string;
      setToastProps({ message: errorMessage, open: true, type: ToastType.error });
      logSentryError(errorMessage);
    },
  });

  const markAllNotificationsRead = () => {
    trackMixpanelEvent(MixpanelEventName.NOTIFICATION_MARK_ALL_AS_READ_CONFIRM_CLICKED, {
      'provider id': providerId ?? '',
      'logged in provider name': providerName ?? '',
      'selected provider name': selectedProviderName ?? '',
    });
    markAllNotificationsAsRead();
  };

  const hasUnreadNotifications = notifications.some((notification) => notification.unread);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastProps(undefined);
  };

  const getNotificationRecordLabel = (item: Notification) => `${
    item.action === NotificationAction.HEALTH_RECORD_REFRESH_NOTIFICATION ? 'New ' : ''
  }${item.metadata?.hrCount > 1 ? 'Records' : 'Record'}`;


  return (
    <>
      {toastProps ? <Toast {...toastProps} onClose={handleClose} /> : null}
      <Drawer
        anchor='right'
        open={isOpen}
        onClose={handleDrawerClose}
        sx={{
          height: '100%',
          width: '35%',
          '& .MuiDrawer-paper': { width: '35%', boxSizing: 'border-box' },
        }}
      >
        <Box display='flex' flexDirection='column' flex={1} overflow='hidden'>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            p={2}
            pb={3}
            mt={1}
            mb={1}
            alignItems='center'
            justifyContent='space-between'
            height={10}
            sx={{ borderBottom: `1px solid ${Theme.custom.colors.backgroundColorSecondary}` }}
          >
            <Typography fontSize={20} fontWeight={500} color={Theme.custom.colors.lightTextPrimary} variant='h6'>
              Notifications
            </Typography>
            <Close sx={{ color: '#0000008A' }} onClick={handleDrawerClose} />
          </Box>
          {!isConfirmDialogOpen ? (
            <Box pt={2} pl={2} pr={2} height='100%'>
              <Box display='flex' justifyContent='space-between'>
                <Tabs
                  value={tabIndex}
                  onChange={(e, newValue) => {
                    setTabIndex(newValue);
                    setOffset(0);
                    setNotifications([]);
                    if (newValue === 0) {
                      trackMixpanelEvent(MixpanelEventName.NOTIFICATION_ALL_TAB_CLICKED, {
                        count: (notificationsCount.data?.notifications.total ?? 0).toString(),
                        'provider id': providerId ?? '',
                        'logged in provider name': providerName ?? '',
                        'selected provider name': selectedProviderName ?? '',
                      });
                    }
                    else if (newValue === 1) {
                      trackMixpanelEvent(MixpanelEventName.NOTIFICATION_UNREAD_TAB_CLICKED, {
                        count: (notificationsCount.data?.notifications.unread ?? 0).toString(),
                        'provider id': providerId ?? '',
                        'logged in provider name': providerName ?? '',
                        'selected provider name': selectedProviderName ?? '',
                      });
                    }
                  }}
                  sx={{
                    '& .MuiTabs-indicator': {
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: 'transparent',
                      '& > span': {
                        maxWidth: tabIndex === 0 ? '40%' : '66%',
                        width: '100%',
                        backgroundColor: Theme.custom.colors.primaryMain,
                      },
                    },
                  }}
                  TabIndicatorProps={{ children: <span /> }}
                >
                  <Tab
                    label='ALL'
                    sx={{ color: tabIndex === 0 ? Theme.custom.colors.primaryMain
                      : Theme.custom.colors.lightTextSecondary,
                    fontSize: 14,
                    fontWeight: 500 }}
                  />
                  <Tab
                    label='UNREAD'
                    sx={{ color: tabIndex === 1 ? Theme.custom.colors.primaryMain
                      : Theme.custom.colors.lightTextSecondary,
                    fontSize: 14,
                    fontWeight: 500 }}
                  />
                </Tabs>
                <Button
                  variant='outlined'
                  size='large'
                  sx={{ textTransform: 'none', fontWeight: 'medium', borderRadius: 1, px: 2.75, py: 1 }}
                  onClick={() => markAllNotificationsButton()}
                  disabled={!hasUnreadNotifications}
                >
                  MARK ALL AS READ
                </Button>
              </Box>
              <Divider sx={{ my: 0.50, marginTop: 2 }} />
              <Box
                sx={{
                  flex: 1,
                  overflowY: 'scroll',
                  maxHeight: 'calc(100% - 150px)',
                }}
              >
                <InfiniteScroll
                  items={notifications}
                  hasMore={total > notifications.length}
                  isLoading={isFetching}
                  loadMore={fetchMoreNotifications}
                  getItemKey={(item) => item.id}
                  renderItem={(item, index) => (
                    <React.Fragment key={item.id}>
                      <ListItem
                        alignItems='flex-start'
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          px: 0.75,
                          py: 1.5,
                        }}
                      >
                        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                          <Box display='flex' alignItems='center' gap={1}>
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                bgcolor: Theme.custom.colors.lightInfoMain,
                                borderRadius: '50%',
                                opacity: item.unread ? 1 : 0,
                              }}
                            />
                            <Typography
                              fontWeight={item.unread ? 700 : 400}
                              color={Theme.custom.colors.lightTextPrimary}
                              sx={{ fontSize: 16 }}
                            >
                              {item.message}
                            </Typography>
                          </Box>
                          <Box>
                            <Tooltip
                              title={item?.createdAt
                                ? formatGoogleDate(item.createdAt, true) : null}
                              arrow
                            >
                              <Typography variant='body1' color={Theme.custom.colors.lightTextSecondary} sx={{ fontSize: 16 }}>
                                {formatTimeDifference(item?.createdAt)}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                          <Box display='flex' alignItems='center'>
                            <ListItemText
                              sx={{ pl: 2.5, width: 120 }}
                              primary={(
                                <>
                                  <Typography variant='body1' color={Theme.custom.colors.lightTextSecondary}>
                                    Patient
                                  </Typography>
                                  <Typography variant='body1' color={Theme.custom.colors.lightTextSecondary}>
                                    {getNotificationRecordLabel(item)}
                                  </Typography>
                                </>
              )}
                            />
                            <ListItemText
                              sx={{ pl: 5 }}
                              primary={(
                                <>
                                  <Typography variant='body1' color={Theme.custom.colors.lightTextPrimary} className='fs-mask'>
                                    {`${item.metadata?.patient?.name?.firstName} ${item.metadata?.patient?.name?.lastName}`}
                                  </Typography>
                                  <Typography variant='body1' color={Theme.custom.colors.lightTextPrimary}>
                                    {item.metadata?.hrCount}
                                  </Typography>
                                </>
              )}
                            />
                          </Box>
                          {item.action !== NotificationAction.EMPTY_NOTIFICATION && item.action !== '' && (
                          <Box>
                            <Button
                              variant='outlined'
                              endIcon={<ArrowForwardIcon />}
                              size='large'
                              sx={{ textTransform: 'none', fontWeight: 'medium', borderRadius: 1, px: 2.75, py: 1 }}
                              onClick={handleNotificationClick(
                                item.id,
                                item.metadata.refreshId,
                                item.metadata.patient.id as string,
                                item.metadata.hrCount,
                                item.action,
                              )}
                            >
                              VIEW
                            </Button>
                          </Box>
                          )}
                        </Box>
                      </ListItem>
                      {index !== notifications.length - 1 && <Divider sx={{ my: 1 }} />}
                    </React.Fragment>
                  )}
                />

                {!isFetching && notifications.length === 0 && (
                <Box display='flex' justifyContent='center'>
                  <Typography variant='body1' color={Theme.custom.colors.lightTextSecondary} sx={{ fontSize: 16 }}>
                    No notifications available
                  </Typography>
                </Box>
                )}

              </Box>
            </Box>
          ) : (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='50%'
              flexDirection='column'
            >
              <Typography>Do you want to mark all notifications as read?</Typography>
              <Box mt={3}>
                <Button
                  variant='contained'
                  size='large'
                  sx={{ textTransform: 'none', fontWeight: 'medium', borderRadius: 1, px: 2.75, py: 1, backgroundColor: Theme.custom.colors.primaryMain }}
                  onClick={() => markAllNotificationsRead()}
                >
                  MARK ALL AS READ
                </Button>
                <Button
                  variant='outlined'
                  size='large'
                  sx={{ textTransform: 'none',
                    fontWeight: 'medium',
                    borderRadius: 1,
                    px: 2.75,
                    py: 1,
                    borderColor: Theme.custom.colors.primaryMain,
                    color: Theme.custom.colors.primaryMain,
                    marginLeft: 3 }}
                  onClick={() => {
                    trackMixpanelEvent(MixpanelEventName
                      .NOTIFICATION_MARK_ALL_AS_READ_CANCEL_CLICKED, {
                      'provider id': providerId ?? '',
                      'logged in provider name': providerName ?? '',
                      'selected provider name': selectedProviderName ?? '',
                    });
                    setIsConfirmDialogOpen(false);
                  }}
                >
                  CANCEL
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default NotificationsDrawer;

import { MedicationAPIResponse, MedicationTimeLineChart, RefillsApiResponse, MedicationCountAPIResponse } from 'pages/Dashboard/types/medication.types';
import { QueryFunctionContext } from 'react-query';
import { medicationsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchMedicationList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MedicationAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(medicationsUrls.listMedications.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export const fetchAllMedicationList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MedicationAPIResponse> {
  const [, providerId, patientId, refreshId] = queryKey;
  return (
    method(medicationsUrls.listMedications.apiUrls(
      providerId as string,
      patientId as string,
      refreshId as string,
    ).listAll)
  );
};

export const fetchCurrentMedicationList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MedicationAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(medicationsUrls.listMedications.apiUrls(
      providerId as string,
      patientId as string,
    ).listCurrent)
  );
};

export const fetchRefills = (method: HttpType['get'], identifierType: 'meta' | 'pmi' = 'meta') => function async({ queryKey }: QueryFunctionContext): Promise<RefillsApiResponse> {
  const [, patientId, medicationMetaId, providerId] = queryKey;
  return (
    method(medicationsUrls.refills.apiUrls(
      patientId as string,
      medicationMetaId as string,
      providerId as string,
      identifierType,
    ).list)
  );
};

export const fetchMedicationChartHistory = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MedicationTimeLineChart> {
  const [, providerId, patientId] = queryKey;
  return (
    method(medicationsUrls.medicationHistory.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export const fetchAllMedicationCount = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MedicationCountAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(medicationsUrls.counts.apiUrls(
      providerId as string,
      patientId as string,
    ).count)
  );
};


import { CategoryType } from 'pages/Dashboard/types/whoiam.types';
import { GooglePhoneNumber } from 'utils/phoneUtils';

export type TimeAudit = {
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type UserAudit = {
  id: string;
  name: string;
  email: string;
};

export type Metadata = {
  provider_id?: string | string[];
  new_email?: string;
  old_email?: string;
  practice_id?: string;
  practice_name?: string;
  staff_id?: string;
  provider_email: string;
  provider_first_name: string;
  provider_last_name: string;
  staff_first_name?: string;
  staff_last_name?: string;
  bundle_items?: string[];
  bundle_name?: string;
  bundle_type?: CategoryType;
  provider_details?: {
    first_name: string;
    last_name: string;
    email: string;
    id: string;
  }[],
  patient_email?: string;
  patient_number?: string;
  patient_id?: string;
};

export type AuditTrail = {
  id: string;
  timeAudit: TimeAudit;
  userAudit: {
    createdByUser: UserAudit;
    updatedByUser: UserAudit;
  };
  action: string;
  description: string;
  metadata: Metadata;
  providerFirstName: string;
  providerLastName: string;
  providerEmail: string;
};

export type Pagination = {
  offset: number;
  limit: number;
  total: string;
};

export type ListAuditLogsAPIResponse = {
  auditTrails: AuditTrail[];
  pagination: Pagination;
};


export type Category = {
  id: string;
  name: string;
  description: string;
  type: CategoryType;
}

export type ListPracticeCategoryByTypeResponse = {
  bundles: {
    bundleId: string;
    categories: Category[]
  }[]
}

export type ListCategoryByTypeResponse = {
  categories: Category[]
}

export enum BundleAction {
  ACTION_TYPE_ADD_BUNDLE_ITEM = 'ACTION_TYPE_ADD_BUNDLE_ITEM',
  ACTION_TYPE_REMOVE_BUNDLE_ITEM = 'ACTION_TYPE_REMOVE_BUNDLE_ITEM'
}

export type UpdatePracticeBundlePayload = {
  bundle_items: {
    item_id: string;
    action: BundleAction;
  }[];
}

export type UpdatedBundle = {
  bundleId: string;
  payload: UpdatePracticeBundlePayload;
  deleteAll?: boolean;
}


export type DeletePatientForm = {
  email?: string;
  mobile?: string;
}

export type DeletePatientPayload = {
  email?: string;
  phone?: GooglePhoneNumber;
}

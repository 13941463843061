import { AllergiesAPIResponse } from 'pages/Dashboard/types/allergies.types';
import { QueryFunctionContext } from 'react-query';
import { allergiesUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const fetchAllergyList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<AllergiesAPIResponse> {
  const [, providerId, patientId, refreshId] = queryKey;
  return (
    method(allergiesUrls.listAllergies.apiUrls(
      providerId as string,
      patientId as string,
      refreshId as string,
    ).list)
  );
};

export default fetchAllergyList;


import { NavigateBefore } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import MyDrawer from 'pages/Dashboard/components/RefillsDrawer';
import Tabs from 'pages/Dashboard/components/Tabs';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import { MedicationCategory, PatientMedication, PatientMedications } from 'pages/Dashboard/types/medication.types';
import React, { useEffect, useState } from 'react';
import theme from 'theme';
import { googleDateToDateString } from 'utils/dateUtils';
import { useParams } from 'react-router-dom';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import UserAddedToolTip from 'pages/Dashboard/components/UserAddedToolTip';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import MedicationChartDrawer from 'pages/Dashboard/components/MedicationChartDrawer';
import { useHttp } from 'hooks/use-fetch';

export enum TabLabels {
  PSYCH = 'PSYCH',
  OTHER = 'OTHER',
}

export interface MedicationCardProps extends Partial<ShowMoreDrawerChildProps> {
  medication?: PatientMedication[];
  showTabs: boolean;
  drawerTabIndex?: number;
  isCurrentRegime: boolean;
  onShowMoreMedication?: (d: PatientMedication[], idx?: number) => void;
  medicationTimeLines?: PatientMedications;
  selectedChip?: boolean;
}

type TabPanelsProps = {
  children?: React.ReactNode;
  medication: PatientMedication[];
  handleSelectedMedication: (index: number) => void;
};

type MedicationFromChart = {
  medicationId: string;
  medicationName: string;
  medicationMetaId: string;
  patientId: string;
};

const getMedicationName = ({ medication }: PatientMedication) => {
  if (medication?.standardDisplayName) {
    return medication?.standardDisplayName;
  }
  const name = medication.fullGenericName.length > 0 && medication.fullGenericName !== ''
    ? medication.fullGenericName
    : medication.fullName;
  const brandName = medication.brandName.length > 0 ? `(${medication.brandName})` : '';
  return `${name} ${brandName}`;
};
const getMedicationDate = (medication: PatientMedication) => {
  if (!medication.started) {
    return '';
  }
  return new Date(
    medication.started.year,
    medication.started.month - 1,
    medication.started.day,
  ).getTime();
};
function addSuperscriptR(str: string) {
  return str.replace(/\(([^)]+)\)$/, '($1®)');
}
function MedicationTabPanels({
  children,
  medication,
  handleSelectedMedication,
}: TabPanelsProps): JSX.Element {
  const unknownDateMedications = medication.filter((med) => !med.started);
  return !medication.length ? (
    <NoDataForNow />
  ) : (
    <>
      {medication.map((currentMedication, index) => {
        if (!currentMedication.started) {
          return null;
        }
        const isSameDate = googleDateToDateString(currentMedication.onsetDate)
          === googleDateToDateString(currentMedication.latestDate);
        const dateString = isSameDate ? googleDateToDateString(currentMedication.onsetDate) : `${googleDateToDateString(currentMedication.onsetDate)}${
          currentMedication.latestDate
            ? ` - ${googleDateToDateString(currentMedication.latestDate)}`
            : ''
        }`;
        return (
          <ButtonBase
            key={`current-regimen-${currentMedication.id}`}
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              '&:hover': {
                backgroundColor: theme.custom.backgroundColor,
              },
            }}
            onClick={() => handleSelectedMedication(index)}
          >
            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginRight: 24,
              }}
            >
              <Typography variant='body1' color={theme.custom.colors.lightTextSecondary} className='fs-mask'>
                {dateString}
              </Typography>
              <Box mb={2} mt={-0.7}>
                <Typography textAlign='left' display='inline-block'>
                  <Typography
                    display='inline'
                    textAlign='left'
                    dangerouslySetInnerHTML={{
                      __html: addSuperscriptR(getMedicationName(currentMedication)?.trim()).replace(
                        /®/g,
                        '<sup>®</sup>',
                      ),
                    }}
                  />
                  <Typography
                    display='inline-block'
                    textAlign='left'
                    style={{ marginLeft: 2 }}
                    color={theme.custom.colors.lightTextSecondary}
                  >
                    {`  ${currentMedication?.medication?.strength}`}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {currentMedication.refillCount > 1 ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '20px',
                    borderRadius: '64px',
                    backgroundColor: theme.custom.colors.backgroundColorSecondary,
                    color: theme.custom.colors.lightTextSecondary,
                    padding: '0 6.5px',
                    mr: 1,
                  }}
                >
                  <Typography
                    color={theme.custom.colors.lightTextSecondary}
                    fontSize={12}
                    fontWeight={500}
                  >
                    {currentMedication.refillCount}
                  </Typography>
                </Box>
              ) : null}
              <UserAddedToolTip source={currentMedication.source} />
              <NavigateBefore
                sx={{
                  color: theme.custom.colors.lightTextSecondary,
                  marginLeft: 'auto',
                  marginRight: 0,
                  transform: 'rotate(180deg)',
                }}
              />
            </Box>
          </ButtonBase>
        );
      })}
      {unknownDateMedications.length ? (
        <>
          <Typography mb={2} mt={3} variant='body1' color={theme.custom.colors.lightTextSecondary}>
            Unknown dates
          </Typography>
          {unknownDateMedications.map((meds) => (
            <Box mb={2}>
              <Typography
                display='inline-block'
                dangerouslySetInnerHTML={{
                  __html: addSuperscriptR(getMedicationName(meds)).replace(/®/g, '<sup>®</sup>'),
                }}
              />
              <Typography variant='subtitle1' color={theme.custom.colors.lightTextSecondary}>
                {`${meds?.medication?.strength}`}
              </Typography>
            </Box>
          ))}
        </>
      ) : null}
      {children}
    </>
  );
}

export default function MedicationCardNew({
  medication = [],
  showTabs,
  drawerTabIndex,
  closeDrawer,
  drawerItem,
  drawerMode = false,
  isCurrentRegime,
  onShowMoreMedication = () => null,
  medicationTimeLines = {} as PatientMedications,
  selectedChip,
}: MedicationCardProps): JSX.Element | null {
  const { id } = useParams();
  const { providerId } = useHttp();
  const [value, setValue] = React.useState(0);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [showChartDrawer, setShowChartDrawer] = React.useState(false);
  const [finalMedsList, setFinalMedsList] = useState<PatientMedication[]>([]);
  const [psychMedication, setPsychMedication] = useState<PatientMedication[]>([]);
  const [otherMedication, setOtherMedication] = useState<PatientMedication[]>([]);
  const [medicationFromChart, setMedicationdFromChart] = useState<MedicationFromChart>();
  const [isOpenedFromChart, setIsOpenedFromChart] = useState(false);

  useEffect(() => {
    const datedMedications = medication
      .filter((meds) => meds.started)
      .sort((a, b) => (getMedicationDate(a) > getMedicationDate(b) ? -1 : 1));
    const nonDatedMedications = medication.filter((meds) => !meds.started);

    const sortedMedications = [...datedMedications, ...nonDatedMedications];
    setFinalMedsList(sortedMedications);
  }, [medication]);
  useEffect(() => {
    const psych = finalMedsList.filter(
      (med) => med.category === MedicationCategory.MEDICATION_CATEGORY_PSYCH,
    );
    const other = finalMedsList.filter(
      (med) => med.category !== MedicationCategory.MEDICATION_CATEGORY_PSYCH,
    );
    setPsychMedication(psych);
    setOtherMedication(other);
  }, [finalMedsList]);

  const [currentMedsList, setCurrentMedsList] = useState<PatientMedication[]>([]);
  const [selectedMedication, setSelectedMedication] = useState<Partial<PatientMedication>>();
  const slicedCurrentMedsList = sliceForShowMore<PatientMedication>(
    currentMedsList,
    !drawerMode,
    6,
  );
  React.useEffect(() => {
    if (drawerMode) {
      setValue(drawerTabIndex || 0);
    }
  }, [drawerTabIndex, drawerMode]);
  const handleSelectedMedicationList = (tab: number) => {
    setValue(tab);
  };
  useEffect(() => {
    setCurrentMedsList(value === 0 ? psychMedication : otherMedication);
  }, [value, psychMedication, otherMedication]);
  useEffect(() => {
    if (!psychMedication.length && otherMedication.length) {
      setValue(1);
    }
  }, [psychMedication, otherMedication]);
  const handleShowMoreMedication = (medicine: PatientMedication[], index?: number) => {
    trackMixpanelEvent(MixpanelEventName.SHOW_MORE_MEDICATION_CLICKED);
    onShowMoreMedication(medicine, index);
  };

  const handleShowMedicationChart = () => {
    setShowChartDrawer(true);
    trackMixpanelEvent(MixpanelEventName.VIEW_MEDICATION_CHART_BUTTON_CLICKED, {
      'patient id': id ?? '',
      'provider id': providerId ?? '',
    });
  };
  const renderTabs = () => (showTabs ? (
    <Box display='flex' flexDirection='column' flexGrow={1}>
      <Box mb={2} sx={{ borderWidth: 1, borderColor: 'red', boxSizing: 'border-box' }}>
        <Tabs
          tabItems={[
            { label: `${TabLabels.PSYCH} (${psychMedication.length})`, value: 0 },
            { label: `${TabLabels.OTHER} (${otherMedication.length})`, value: 1 },
          ]}
          selectedTab={value}
          setSelectedTab={(tab) => handleSelectedMedicationList(tab)}
        />
      </Box>
      <Box display='flex' flexDirection='column' height='100%'>
        <Box flexGrow={1}>
          <MedicationTabPanels
            medication={slicedCurrentMedsList}
            handleSelectedMedication={handleSelectedMedication}
          />
        </Box>
        <Box mt='auto' display='flex' justifyContent='space-between'>
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={() => handleShowMoreMedication(medication, value)}
            items={currentMedsList}
            showMoreThreshold={6}
          />
          {!drawerMode && !selectedChip && (
          <Button
            onClick={() => handleShowMedicationChart()}
            color='primary'
            variant='contained'
            size='medium'
            startIcon={(
              <SsidChartOutlinedIcon
                fontSize='large'
              />
        )}
          >
            VIEW CHART
          </Button>
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    <Box>
      <MedicationTabPanels
        medication={currentMedsList}
        handleSelectedMedication={handleSelectedMedication}
      >
        <ShowMoreButton
          isDrawer={drawerMode}
          onShowMore={() => handleShowMoreMedication(medication, value)}
          items={medication}
        />
      </MedicationTabPanels>
    </Box>
  ));
  const toggleDrawer = () => {
    setShowDrawer((prev) => !prev);
  };
  const toggleChartDrawer = () => {
    setShowChartDrawer((prev) => !prev);
  };
  const handleSelectedMedication = (index: number) => {
    setSelectedMedication(currentMedsList?.[index]);
    trackMixpanelEvent(MixpanelEventName.MEDICATION_CLICKED, {
      medicationName: currentMedsList?.[index]?.medication?.brandName ?? '',
    });
    setIsOpenedFromChart(false);
    toggleDrawer();
  };

  const handleSelectedMedicationFromChart = (
    medicationId: string,
    medicationName: string,
    medicationMetaId: string,
    patientId: string,
  ) => {
    setMedicationdFromChart({ medicationId, medicationName, medicationMetaId, patientId });
    setIsOpenedFromChart(true);
    toggleDrawer();
  };

  let medicationName = '';
  if (isOpenedFromChart) {
    medicationName = medicationFromChart?.medicationName || '';
  }
  else if (selectedMedication) {
    medicationName = getMedicationName(selectedMedication as PatientMedication);
  }

  const { Icon } = HealthRecordCategories.medication.value;
  return medication.length > 0 ? (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='current-regimen-medication'>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {!drawerMode && (
          <CardHeader
            avatar={<Icon />}
            title='Medications'
            titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
          />
        )}
        {drawerMode && (
          <ShowMoreDrawerTitle
            closeDrawer={closeDrawer as VoidFunction}
            drawerItem={drawerItem as string}
          />
        )}
        <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
          {!medication.length ? <NoDataForNow /> : <>{renderTabs()}</>}
        </CardContent>
      </Card>
      <MyDrawer
        toggleDrawer={toggleDrawer}
        isOpen={showDrawer}
        medicationId={isOpenedFromChart ? medicationFromChart?.medicationId || '' : selectedMedication?.id ?? ''}
        medicationName={medicationName}
        medicationMetaId={isOpenedFromChart ? medicationFromChart?.medicationMetaId || '' : selectedMedication?.medicationMetaId ?? ''}
        patientId={isOpenedFromChart ? medicationFromChart?.patientId || '' : id || ''}
      />
      <MedicationChartDrawer
        isOpen={showChartDrawer}
        toggleDrawer={toggleChartDrawer}
        medicationTimeLines={medicationTimeLines}
        handleSelectedMedication={handleSelectedMedicationFromChart}
      />
    </Grid>
  ) : null;
}

import { LabAPIResponse } from 'pages/Dashboard/types/lab.types';
import { QueryFunctionContext } from 'react-query';
import { labsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchLabList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<LabAPIResponse> {
  const [, providerId, patientId, refreshId] = queryKey;
  return (
    method(labsUrls.listLabs.apiUrls(
      providerId as string,
      patientId as string,
      refreshId as string,
    ).list)
  );
};

export const fetchLabListReport = (
  method: HttpType['post'],
  providerId: string,
  patientId: string,
  labId: string,
) => method(labsUrls.labReport.apiUrls(
  providerId as string,
  patientId as string,
  labId as string,
).report, {});
